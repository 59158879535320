.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 60px;
  width: 100%;
  line-height: 60px;
  background: #d8eefc;
}

.image-santa {
  max-width: 100%;
  height: auto;
}

.explain-header {
  color: #357edd;
}

.auto-resizable-iframe {
  max-width: 560px;
  margin: 0px auto;
}

.auto-resizable-iframe > div {
  position: relative;
  padding-bottom: 75%;
  height: 0px;
}

.auto-resizable-iframe iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
